<template>
  <div>
    <h2>{{ $t("Bestellungen") }}</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="panel p-1">
          <div class="overflow-auto">
            <b-table
              id="my-table"
              :items="orders"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :class="'table-' + theme()"
              striped
              small
              @row-clicked="goToOrder"
            >
              <template v-slot:cell(date)="data">
                {{ date(data.value) }}
                <br />
                <span class="color">{{ time(data.value) }}</span>
              </template>
              <template v-slot:cell(amount)="data">
                <span class="color">
                  <b>{{ data.value | currency(cSymb) }}</b>
                </span>
              </template>
              <template v-slot:cell(payMethod)="data">{{
                $t(payment(data.value))
              }}</template>
              <template v-slot:cell(orderId)="data">
                <div class="cut">{{ data.value }}</div>
              </template>
            </b-table>
          </div>
        </div>
        <b-pagination
          v-model="currentPage"
          align="center"
          class="mt-3"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DataService from "@/services/DataService";
import Vue from "vue";

export default {
  name: "page-orderlist",
  props: {},
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "date",
          label: Vue.i18n.translate("Datum"),
          sortable: true,
        },
        {
          key: "amount",
          label: "Total",
          sortable: true,
        },
        {
          key: "payMethod",
          label: Vue.i18n.translate("Zahlart"),
          sortable: true,
        },
        {
          key: "orderId",
          label: "ID",
          tdClass: "cut",
        },
      ],
      orders: [],
    };
  },
  computed: {
    ...mapGetters(["userData", "companyData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
    rows() {
      return this.orders.length;
    },
  },
  methods: {
    date(value) {
      var a = new Date(value);
      var year = a.getFullYear();
      var month = a.getMonth() + 1;
      var date = a.getDate();
      var time = date + "/" + month + "/" + year;
      return time;
    },
    time(value) {
      var a = new Date(value);
      var hour = a.getHours();
      var min = a.getMinutes();
      if (min.toString().length == 1) {
        min = "0" + min;
      }
      var time = hour + ":" + min;
      return time;
    },
    dateTime(value) {
      var a = new Date(value);
      var year = a.getFullYear();
      var month = a.getMonth() + 1;
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      if (min.toString().length == 1) {
        min = "0" + min;
      }
      var day = date + "/" + month + "/" + year;
      var hor = hour + ":" + min;
      var time = day + "\n" + hor;
      return time;
    },

    payment(value) {
      if (value == 1) {
        return "Kredit Karte";
      }
      if (value == 2) {
        return "Hotel Karte";
      }
      if (value == 3) {
        return "Paypal";
      }
      if (value == 4) {
        return "Bar bei Abholung/Lieferung";
      }
    },
    rowClicked(event) {
      console.log(event.orderId);
    },
    goToOrder(event) {
      this.$router.push({
        name: "posList",
        params: { orderId: event.orderId },
      });
    },
    goBack() {
      setTimeout(() => {
        this.$router.push({ name: "order" });
      }, 300);
    },
    theme() {
      var hexcolor = this.companyData.shopBackgroundColorRgb.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "light" : "dark";
    },
  },
  created: function () {
    DataService.getOrders(this.companyData.apiKey, this.userData.token).then(
      (response) => {
        this.orders = response.data;
      }
    );
  },
};
</script>
<style scoped>
table {
  margin-bottom: 0;
}
.noWrap {
  white-space: nowrap;
}
.doWrap {
  word-wrap: break-word;
}
.pointer {
  cursor: pointer;
}
</style>
